<template>
  <div class="vg_wrapper" v-loading='isLoading'>
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="psinForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('psinForm')"/>
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="psinForm" :rules="rules" :model="psinForm" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="出库编号" prop="psin_no">
            <el-input v-model="psinForm.psin_no" maxlength="10"  show-word-limit placeholder="请填写出库编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="出库时间" prop="psin_time">
            <el-date-picker
              v-model="psinForm.psin_time"
              type="date"
              placeholder="选择出库时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow(0)">导入成品待入库</el-button>
        <el-button type="primary" plain size="small" @click="addRow(1)">导入产品</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <PsinAddEditIn @handleSelectionChange="handleSelect" :psinForm="psinForm"></PsinAddEditIn>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <PsinAddEditPpin @confirmIn="confirmIn" ref="PsinAddEditPpin"></PsinAddEditPpin>
      </el-dialog>
      <el-dialog
          :title="title"
          :visible.sync="notice1"
          width="70%">
        <PsinAddEditProd @confirmIn="confirmIn" ref="PsinAddEditProd"></PsinAddEditProd>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData" :stffForm="stffForm" v-if="psinForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import PsinAddEditPpin from "@/views/StockManagement/PsinManage/TabChild/Component/PsinAddEditPpin";
import PsinAddEditProd from "@/views/StockManagement/PsinManage/TabChild/Component/PsinAddEditProd";
import PsinAddEditIn from "@/views/StockManagement/PsinManage/TabChild/Component/TabPanel/PsinAddEditIn";
import {psinAPI} from "@api/modules/psin";
export default {
  name: "PsinAddMain",
  components: {
    editHeader,
    inputUser,
    PsinAddEditIn,
    PsinAddEditPpin,
    PsinAddEditProd,
  },
  data(){
    return{
      psinForm:{},
      rules:{
        psin_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        psin_time:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      removeList:[],
      stffForm:{
        stff_name:'',
        cptt_name:'',
        dept_name:'',
        dept_team_name:'',
        stff_id:Number,
        cptt_id:Number,
        dept_id:Number,
        dept_team_id:Number,
      },
      psinList:[],
      copyForm:[],
      notice:false,
      title:'',
      notice1:false,
      isShow: true,
      btn:{},
      isLoading:false 
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.getList()
     },
     //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    //保存
    saveInfo() {
      let psinForm = {};
      psinForm = Object.assign(psinForm,this.psinForm);
      psinForm.psin_time = (new Date(psinForm.psin_time)).getTime() / 1000;
      let arr = this.psinForm.psin_prod_list;
      let bbj = this.helper.deleteSaveBody(this.psinForm,arr,this.copyForm,1);
      psinForm.psin_prod_list = psinForm.psin_prod_list.concat(bbj) ;
      this.isLoading = true
      post(psinAPI.editPsin,psinForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.isShow = true;
              this.$emit('isShow',this.isShow)
              this.initData()
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'出库编号重复'
              })
              // this.psinForm.psin_time = (new Date(this.psinForm.psin_time)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              // this.psinForm.psin_time = (new Date(this.psinForm.psin_time)).getTime() * 1000;
            }
            setTimeout(()=>{
              this.isLoading = false
            },500)
          }).catch(res =>{
            let msg = res.data.msg 
            this.$message.error(msg)
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('psinForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{

      })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    //删除选择的内容
    handleSelect(val){
      this.removeList = val;
    },
    // 删除物料信息按钮
    delBank(){
      for(let i=0;i<this.removeList.length;i++){
        if(this.psinForm.psin_prod_list.indexOf(this.removeList[i]) !== -1){
          let index = this.psinForm.psin_prod_list.indexOf(this.removeList[i]);
          this.psinForm.psin_prod_list.splice(index,1)
        }
      }
    },
    // 打开导入物料信息小窗口
    addRow(num){
      if(num === 0){
        this.notice = true;
      }else if(num === 1){
        this.notice1 = true;
      }
    },
    // 导入小窗口确定按钮
    confirmIn(val){
      this.notice = false;
      this.notice1 = false;
      if(val.length>0){
        this.psinList = val;
        let psinListCopy = [];
        for(let i=0;i<this.psinList.length;i++){
          let temp = {};
          if(this.psinList[i].ppin_id){
            temp.ppin_id = this.psinList[i].ppin_id;
            temp.stay_ppin_num = parseInt(this.psinList[i].pcon_prod_num)-parseInt(this.psinList[i].ppin_num);
          }
          temp.prod_id = this.psinList[i].prod_id;
          temp.prod_no = this.psinList[i].prod_no;
          temp.prod_name = this.psinList[i].prod_name;
          temp.prod_spec = this.psinList[i].prod_spec;
          temp.prod_type = this.psinList[i].prod_type;
          psinListCopy.push(temp)
        }
        this.psinForm.psin_prod_list = this.psinForm.psin_prod_list.concat(psinListCopy);
        if(this.psinList[0].ppin_id){
          this.$refs.PsinAddEditPpin.clear()
        }else{
          this.$refs.PsinAddEditProd.clear()
        }
      }
    },
    // 获取信息步骤
    getList(){
      let psin_id = this.$route.query.form_id
      get(psinAPI.getPsinById,{psin_id:psin_id})
      .then(res=>{
        if (res.data.code === 0) {
           // 权限按钮
          this.btn = res.data.data.btn;
          this.psinForm = res.data.data.form;
          this.getInputUser()
          this.psinForm.psin_time = this.psinForm.psin_time*1000;
          this.copyForm = Object.assign(this.copyForm,this.psinForm.psin_prod_list)
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //获取录入人
    getInputUser(){
      this.stffForm.cptt_id = this.psinForm.cptt_id;
      this.stffForm.user_id = this.psinForm.user_id;
      this.stffForm.dept_id = this.psinForm.dept_id;
      this.stffForm.stff_id = this.psinForm.stff_id;
      this.stffForm.dept_name = this.psinForm.dept_name;
      this.stffForm.dept_team_id = this.psinForm.dept_team_id;
      this.stffForm.dept_team_name = this.psinForm.dept_team_name;
      this.stffForm.stff_name = this.psinForm.stff_name;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.psinForm.psin_prod_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
</style>